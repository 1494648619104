<template>
  <div>
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <!------------- list page start ------------>
      <b-row v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <b-row>
              <b-col cols="2">
                <el-button type="primary" @click="add_delivery" v-acl:DeliveryEdit.enable.show>{{
                  $t("Add")
                }}</el-button>
              </b-col>
              <b-col cols="10">
                <div v-if="!isSortableEnabled">
                  <el-button
                    type="primary"
                    @click="enableSort"
                    v-acl:DeliveryEdit.enable.show
                  >
                  {{ $t("Sort Order") }}
                  </el-button>
                </div>
                <div v-else>
                  <el-button type="primary" @click="disableSort" v-acl:DeliveryEdit.enable.show>
                    {{ $t("Save Change") }}
                  </el-button> &emsp;
                  Please drag and drop the table.
                </div>
              </b-col>
            </b-row>
            <b-row class="m-3">
            </b-row>
            <b-row>
              <b-col cols="4">
                <div v-acl:DeliveryEdit.enable.show>
                &emsp;
                  {{$t("Explanation") + ": " + $t("Sort first with highest priorityt.")}}
                </div>
              </b-col>
            </b-row>
            <p class="p-3" v-if="deliverylist">
              <el-table
                stripe
                :class="{handshape:isSortableEnabled}"
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="deliverylist"
                @row-click="row_clicked_event"
                ref="dragTable"
                row-key="IDn"
              >
                <el-table-column :label="$t('Name')">
                  <template v-slot="{ row }">
                    {{ row.Name }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Delivery') +  $t('Type')">
                  <template v-slot="{ row }">
                    <div v-if="row.DeliveryType == 1">{{ $t("Store Delivery") }}</div>
                    <div v-else-if="row.DeliveryType == 2">{{ $t("Chinesehao") }}</div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('SubTotal Price')">
                  <template v-slot="{ row }">
                    <div v-if="row.SubTotalPrice == 0">{{ $t("No Restriction") }}</div>
                    <div v-else>{{ row.SubTotalPrice }}</div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Status')" prop="Status">
                    <template v-slot="{ row }">
                      <div v-if="row.Active == 1">{{ $t("Active") }}</div>
                      <div v-else>{{ $t("Disable") }}</div>
                    </template>
                  </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <!------------- edit page start ------------>
      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
              <!-- 1 line -->
                <el-row :gutter="10">
                  <el-col :span="6">
                    <base-input :label="$t('Name')">
                      <b-input
                        placeholder="Name"
                        class="form-control"
                        v-model="cur_delivery.Name"
                        required
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6">
                    <base-input :label="$t('Status')">
                      <select
                        class="form-control"
                        v-model="cur_delivery.Active"
                      >
                        <option value="1">{{ $t("Active") }}</option>
                        <option value="0">{{ $t("Disable") }}</option>
                      </select>
                    </base-input>
                  </el-col>
                </el-row>
                <!-- 2 line -->
                <el-row :gutter="10" class="mt-3"  >
                  <el-col :span="6">
                    <base-input :label="$t('Delivery') +  $t('Type')">
                      <select class="form-control" v-model="cur_delivery.DeliveryType">
                        <option value="1">{{$t('Store Delivery')}}</option>
                        <option value="2">{{$t('Chinesehao')}}</option>
                      </select>
                    </base-input>
                  </el-col>                  
                  <el-col :span="6" v-if="cur_delivery.DeliveryType=='1'">
                    <base-input :label="$t('SubTotal Price')">
                      <b-input
                        :placeholder="$t('SubTotal Price')"
                        class="form-control"
                        v-model="cur_delivery.SubTotalPrice"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6" v-if="cur_delivery.DeliveryType=='1'">
                    <base-input :label="$t('Delivery Fee')">
                      <b-input
                        :placeholder="$t('Delivery Fee')"
                        class="form-control"
                        v-model="cur_delivery.DeliveryFee"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6" v-if="cur_delivery.DeliveryType=='2'">
                    <base-input :label="$t('Store') + $t('Address')">
                      <b-input
                        :placeholder="$t('Store') + $t('Address')"
                        class="form-control"
                        v-model="cur_delivery.ThrdDeliAddrFrm"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6" v-if="cur_delivery.DeliveryType=='2'">
                    <base-input :label="$t('Customer') + $t('Address')">
                      <b-input
                        :placeholder="$t('Customer') + $t('Address')"
                        class="form-control"
                        v-model="cur_delivery.ThrdDeliAddrTo"
                      />
                    </base-input>
                  </el-col>
                  <el-col :span="6" v-if="cur_delivery.DeliveryType=='2'">
                    <base-input :label="$t('Delivery Fee')">
                      <b-input
                        :placeholder="$t('Delivery Fee')"
                        class="form-control"
                        v-model="cur_delivery.ThrdDeliFee"
                      />
                    </base-input>
                  </el-col>
                </el-row>
                <b-row class="mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  // Checkbox,
  Row,
  Col,
  // Dropdown,
  // DropdownMenu,
  // DropdownItem,
  // DatePicker,
  // TimeSelect,
} from "element-ui";
import { getStore, setStore } from "../../util/helper";
import Sortable from "sortablejs";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    // [Checkbox.name]: Checkbox,
    [Row.name]: Row,
    [Col.name]: Col,
    // [Dropdown.name]: Dropdown,
    // [DropdownMenu.name]: DropdownMenu,
    // [DropdownItem.name]: DropdownItem,
    // [DatePicker.name]: DatePicker,
    // [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      currpage: "list",
      cur_delivery: null,
      cur_opt: null,
      new_delivery: {
        IDn: "",
        Name: "",
        Active: "1",
        ApplyOrder: "",
        DeliveryType: 1,
        SubTotalPrice: 0,
        DeliveryFee: 0,
        ThrdDeliAddrFrm: "",
        ThrdDeliAddrTo: "",
        ThrdDeliFee: 0
      },
      deliverylist: null,
      // delay_timer: null,

      dismessage: "",
      countdown: 0,

      sortable: null,
      isSortableEnabled: false,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    hasUtilityAuth,
    // for sort --------------------start----
    disableSort() {
      this.sortable.option('disabled', true);
      this.isSortableEnabled = false;
      this.order_submit();
    },
    enableSort() {
      if (this.deliverylist.length <= 0) {
        return;
      }
      this.setSort();
      // this.sortable.option('disabled', false);
      this.isSortableEnabled = true;
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          // console.log("onEnd targetRow A:", evt.newIndex, evt.oldIndex, targetRow, this.deliverylist);
          const targetRow = this.deliverylist.splice(evt.oldIndex, 1)[0];
          this.deliverylist.splice(evt.newIndex, 0, targetRow);
          // console.log("onEnd targetRow B:", this.deliverylist);
        },
      });
    },
    async order_submit() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var orderlist = [];
      for (var i = 0; i < this.deliverylist.length; i++){
        orderlist.push({IDn:this.deliverylist[i].IDn, ApplyOrder:i+1});
      }
      bodyFormData.append("SortOrderList", JSON.stringify(orderlist));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/delivery/sort_order",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.deliverylist = rt.data.deliverys;
              // that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    // for sort ---------------end----

    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },

    add_delivery() {
      this.currpage = "edit";
      this.cur_delivery = this.new_delivery;
      this.datavalue1 = "";
      this.datavalue2 = "";
      this.datavalue3 = "";
      this.datavalue4 = "";
      this.btnselectchange();
      this.cur_opt = "add";
    },
    row_clicked_event(row, column, event) {
      // console.log("row_clicked_event", row, event, column);
      // if (  column &&  column.label == "Active" ) {
      //   // Skip some columns by Label
      //   return;
      // }
      if (!hasUtilityAuth('DeliveryEdit')) return;
      this.currpage = "edit";
      this.cur_delivery = JSON.parse(JSON.stringify(row));

      this.btnselectchange();
      this.cur_opt = "update";
    },
    btnselectchange() {
      let color = this.cur_delivery.BtnColor;
      this.btnselectstyle = { backgroundColor: color };
    },
    form_cancel() {
      this.currpage = "list";
      this.cur_delivery = null;

      // clear sort status
      if(this.isSortableEnabled){
        this.sortable.option('disabled', true);
        this.isSortableEnabled = false;
        // refresh list data
        this.get_delivery_list();
      }
    },
    async form_submit(event, dosomething) {
      if (event) {
        event.preventDefault();
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;

      for (var key in this.cur_delivery) {
        if (this.cur_delivery[key])
          bodyFormData.append(key, this.cur_delivery[key]);
      }

      bodyFormData.append("Name",this.cur_delivery.Name);
      bodyFormData.append("Active",this.cur_delivery.Active);
      bodyFormData.append("DeliveryType",this.cur_delivery.DeliveryType);

      if(this.cur_delivery.DeliveryType == '1'){
        bodyFormData.append("SubTotalPrice",this.cur_delivery.SubTotalPrice);
        bodyFormData.append("DeliveryFee",this.cur_delivery.DeliveryFee);
        bodyFormData.append("ThrdDeliAddrFrm","");
        bodyFormData.append("ThrdDeliAddrTo","");
        bodyFormData.append("ThrdDeliFee",0);
      } else if(this.cur_delivery.DeliveryType == '2'){
        bodyFormData.append("SubTotalPrice",0);
        bodyFormData.append("DeliveryFee",0);
        bodyFormData.append("ThrdDeliAddrFrm",this.cur_delivery.ThrdDeliAddrFrm);
        bodyFormData.append("ThrdDeliAddrTo",this.cur_delivery.ThrdDeliAddrTo);
        bodyFormData.append("ThrdDeliFee",this.cur_delivery.ThrdDeliFee);
      }

      axios({
        method: "post",
        url: "/Api/Web/delivery/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_delivery_list() {
      // if (this.delay_timer) {
      //   clearTimeout(this.delay_timer);
      //   this.delay_timer = null;
      // }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/delivery",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.deliverylist = rt.data.deliverys;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_delivery_list();
    },
  },
  mounted() {
    this.init_data();
    // this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
/* .search-button {
  margin-top: 2rem;
} */
.select_text {
  margin-left: -1rem;
}
.card .table td {
  padding: 0.5rem;
}
.form-group {
  margin-bottom: 0.5rem;
}
.handshape{
  cursor: pointer;
}
</style>
